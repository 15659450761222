<template>
  <ui-component-modal
    modalTitle="Edit social accounts"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveLocation"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-striped is-fullwidth">
        <tbody>
          <tr
            v-for="(account, index) in mLocation.SocialMediaAccounts"
            :key="index"
          >
            <td>{{ account.Name }}</td>
            <td>
              <input type="text" v-model="account.Value" class="input" />
            </td>
            <td>
              <a
                v-if="account.Value"
                :href="account.Url.replace('##value##', account.Value)"
                target="_blank"
                class="has-icon"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'link']" />
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import locationProvider from '@/providers/location'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mLocation: null,
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
  },

  created() {
    this.mLocation = JSON.parse(JSON.stringify(this.location))
  },

  methods: {
    ...mapMutations('locationStore', ['setLocation']),

    saveLocation() {
      let self = this
      self.isSaving = true

      locationProvider.methods
        .saveLocationSocialMediaAccounts(
          self.location.Id,
          self.mLocation.SocialMediaAccounts
        )
        .then((response) => {
          if (response.status === 204) {
            self.setLocation(self.mLocation)

            self.onClickCancel()
          }
        })
        .catch((error) => {
          ////console.log(error.response)
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
